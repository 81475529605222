import {
   LUCKY_fORM, PG_LOGIN,PG_QRCode, PG_TAKEBILL, LUCKY_GAME,PG_CodePage
} from '../config/path'

const routes = [
    {
        path: PG_LOGIN,
        exact: true,
        component: import('../components/auth/LoginPG'),
        isPrivate: false,
        restricted: true
    },
    {
        path: PG_CodePage,
        exact: true,
        component: import('../components/pg/CodePage'),
        restricted: true,
        isPrivate: true,
    },
    {
        path: PG_QRCode,
        exact: true,
        component: import('../components/pg/QRCode'),
        isPrivate: true,
    },
    {
        path: PG_TAKEBILL,
        exact: true,
        component: import('../components/pg/TakeBill'),
        isPrivate: true,
    },
    {
        path: LUCKY_fORM,
        exact: true,
        component: import('../components/luckyForm/LuckyForm'),
    },
    {
        path: LUCKY_GAME,
        exact: true,
        component: import('../components/game/game'),
    }
]

export default routes;