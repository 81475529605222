export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
//code error
export const CODE_ERROR_NOT_FOUND = 'E0006'
export const CODE_ERROR = 'E0003'
export const CODE_ERROR_DATA_NULL = 'E0012'
export const CODE_SUCCESS = 900
//local storage items
export const CAMPAIGNID = 'campaign_id'
export const CUSTOMER_INFOR = 'customer_info'
export const QRCODE_PATH = 'qr_code_path'
export const QRCODE = 'qr_code'
export const MALL_ID = 'mall_id'
export const TOTAL_BILL = 'total_bill'
export const BG_GAME = 'bg_game'
export const PIN = 'pin'
export const ROOTWHEEL = 'root_wheel'
export const GAME_TOKEN = 'game_token'
export const LIST_PROVINCES = 'provinces_data'
export const LIST_STORES = 'stores_data'
export const LIST_DISTRICTS = 'districts_data'
export const LIST_WARDS = 'wards_data'

export const DATE_DEFAULT = 'DD-MM-YYYY'
export const DATE_SERVER = 'YYYY-MM-DD'

export const MALL_HD_ID = 6
export const MALL_BD_ID = 8
export const MALL_HP_ID = 9
export const MALL_BT_ID = 15
export const MALL_TP_ID = 16
export const MALL_LB_ID = 20

export const VEHICLE_TYPE_1 = 'Xe máy'
export const VEHICLE_TYPE_2 = 'Ô tô'
export const VEHICLE_TYPE_3 = 'Taxi'
export const VEHICLE_TYPE_4 = 'Phương tiện công cộng'
export const VEHICLE_TYPE_5 = 'Xe hơi công nghệ'
export const VEHICLE_TYPE_6 = 'Xe ôm công nghệ'
export const VEHICLE_TYPE_0 = 'Khác'
export const REFERRAL_TYPE_1 = 'Website/ Facebook'
export const REFERRAL_TYPE_2 = 'Tờ rơi/ Ấn phẩm'
export const REFERRAL_TYPE_3 = 'Nhân viên bán hàng'
export const REFERRAL_TYPE_4 = 'Từ bạn bè, người thân'
export const REFERRAL_TYPE_5 = 'Quảng cáo trên các trang mạng'
export const REFERRAL_TYPE_6 = 'Bảng thông tin trong trung tâm mua sắm'
export const REFERRAL_TYPE_7 = 'Ứng dụng điện thoại AEONMALL Vietnam'
export const REFERRAL_TYPE_0 = 'Khác'
