import React from 'react'
import { Route, Redirect } from "react-router-dom";
import { isLogin } from "../config/function";
import { PG_CodePage } from "./../config/path";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>(
        isLogin() && restricted ?
        <Redirect to={PG_CodePage} />
        : <Component {...props} />
      ) }
    />
  );
};

export default PublicRoute
