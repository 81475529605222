import React from 'react'
import { Spin} from 'antd'

const RouteLoader = () => {
    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            display: 'flex',
            justifyContent:'center',
            left:0,
            right:0,
        }}>
            <Spin size={'large'} tip={'loading'}/>
        </div>
    )
}

export default RouteLoader
