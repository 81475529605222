import {
  MALL_HD_ID,
  MALL_BD_ID,
  MALL_LB_ID,
  MALL_TP_ID,
  MALL_BT_ID,
  MALL_HP_ID,
  CAMPAIGNID,
  MALL_ID,
} from "./const";
import TP from "../../src/assets/img/TPweb.png";
import BD from "../../src/assets/img/BDweb.png";
// import BDNoel from "../../src/assets/img/BDNoelweb.png";
import LB from "../../src/assets/img/LBweb.png";
import HD from "../../src/assets/img/HDweb.png";
import HP from "../../src/assets/img/HPweb.png";
import BT from "../../src/assets/img/BTweb.png";

import TPMobile from "../../src/assets/img/TP.png";
import BDMobile from "../../src/assets/img/BD.png";
import LBMobile from "../../src/assets/img/LB.png";
import HDMobile from "../../src/assets/img/HD.png";
import HPMobile from "../../src/assets/img/HP.png";
import BTMobile from "../../src/assets/img/BT.png";
// import BDNoelMobile from "../../src/assets/img/BDNoel.png";

export const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    Previous
  </button>
);

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    Next
  </button>
);

export const Background = (mall) => {
  if (mall === MALL_BD_ID) {
    return BD;
  } else if (mall === MALL_HD_ID) {
    return HD;
  } else if (mall === MALL_LB_ID) {
    return LB;
  } else if (mall === MALL_TP_ID) {
    return TP;
  } else if (mall === MALL_HP_ID) {
    return HP;
  } else if (mall === MALL_BT_ID) {
    return BT;
  }
};

export const BackgroundResize = (mall) => {
  if (mall === MALL_BD_ID) {
    return BDMobile;
  } else if (mall === MALL_HD_ID) {
    return HDMobile;
  } else if (mall === MALL_LB_ID) {
    return LBMobile;
  } else if (mall === MALL_TP_ID) {
    return TPMobile;
  } else if (mall === MALL_HP_ID) {
    return HPMobile;
  } else if (mall === MALL_BT_ID) {
    return BTMobile;
  }
};

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "01";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

export const birthdayFormat = (val) => {
  let day = limit(val.substring(0, 2), "31");
  let month = limit(val.substring(2, 4), "12");
  let year = val.substring(4, 8);
  let yearCurrent = new Date().getFullYear();
  if (year > yearCurrent) {
    year = yearCurrent;
  }
  return day + ("-" + month) + ("-" + year);
};

export const isLogin = () => {
  return localStorage.getItem(CAMPAIGNID);
};
