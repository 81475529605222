import React from "react";
import { Button, Result, Layout } from "antd";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            onClick={() => history.goBack()}
            className={"btn btn-common"}
            size={"large"}
          >
            Back
          </Button>
        }
      />
    </Layout>
  );
};

export default NotFound;
